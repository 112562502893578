<template>
  <div class="mainContainer container" style="">
    <div style="display:flex;">
      <back-button />
      <div class="pageTitle">Reset Password</div>
    </div>


    <div class="row" v-if="pageStatus !== 'resetSuccess'">
      <div class="col-md-12">

        <div class="card-container">
          <form @submit.prevent="handlePasswordReset">
              <div class="form-group">
                <label for="email">Email</label>
                <input name="email" type="email" class="form-control" v-model="email"/>
                <ul class="error-feedback" v-if="emailErrors.length > 0" v-html="emailErrors"></ul>
              </div>

            <div class="form-group" v-if="resetCodeShow || this.resetCode">
              <label for="resetCode" v-if="!this.resetCode">A verification code has been sent to your email, please enter it below within 15 minutes:</label>
              <label for="resetCode" v-else>Verification Code</label>
              <input name="resetCode" type="text" class="form-control" v-model="resetCode"/>
              <ul class="error-feedback" v-if="resetCodeErrors.length > 0" v-html="resetCodeErrors"></ul>
            </div>

            <div class="form-group" v-if="pswdFldShow">
              <label for="password">New Password</label>
              <input name="password" type="password" class="form-control"  v-model="password"/>
              <ul class="error-feedback" v-if="passwordErrors.length > 0" v-html="passwordErrors"></ul>
            </div>

            <div class="form-group" v-if="pswdFldShow">
              <label for="passwordConfirmation">Confirm New Password </label>
              <input id="passwordConfirmation" name="passwordConfirmation" type="password"  class="form-control" v-model="passwordConfirmation"/>
              <ul class="error-feedback" v-if="passwordConfirmationErrors.length > 0" v-html="passwordConfirmationErrors"></ul>
            </div>

              <div class="form-group" >
                <button class="btn btn-primary btn-block" :disabled="loading" @click="handlePasswordReset">
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span v-if="this.pageStatus === 'getResetCode'">Email Me Verification Code</span>
                  <span v-if="this.pageStatus === 'validateResetCode'">Validate Verification Code</span>
                  <span v-if="this.pageStatus === 'resetPassword'">Reset</span>
                </button>
              </div>


          </form>

          <div
              v-if="message"
              class="alert"
              :class="successful ? 'alert-success' : 'alert-danger'" v-html="message"
          >
          </div>
        </div>
      </div>
    </div>

    <div class="row resetSuccess" v-if="pageStatus === 'resetSuccess'">
      <div class="col-md-12 msg">
        Your password has been successfully reset. Continue to <span class="alink" @click="this.$root.gotoRoute({ path: '/login' })">Log In</span>.
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "PasswordReset",
  components: {

  },
  data() {


    return {
      successful: false,
      loading: false,
      message: "",
      pageStatus:'getResetCode',
      email:null,
      resetCode:null,
      resetCodeValid: true,
      password:null,
      passwordConfirmation:null,

      emailErrors:'',
      resetCodeErrors:'',
      passwordErrors:'',
      passwordConfirmationErrors:'',
      hasValidationError: false,

    };
  },
  computed: {
    resetCodeShow(){
      return this.pageStatus === 'validateResetCode' || this.pageStatus === 'resetPassword';
    },
    pswdFldShow(){
      return this.pageStatus === 'resetPassword';
    }

  },
  created() {
    this.email = this.$route.query.email;
    this.resetCode = this.$route.query.code;
    if(this.resetCode) this.pageStatus = 'resetPassword'
  },
  mounted() {
    document.title = "Reset Password";
  },
  methods: {
    handlePasswordReset() {
      this.$root.playClickSound();


      this.hasValidationError = false;
      this.validateEmail();

      if(this.hasValidationError) return;


      this.message = "";
      this.successful = false;
      this.loading = true;

      if(this.pageStatus === 'getResetCode'){
        this.axios
            .post(this.$hostApi + 'resetpass/',{task:'getPasswordResetCode', email:this.email})
            .then(response =>{
              //console.log(response);
              if(response.data === 'ok'){
                this.pageStatus = 'validateResetCode';
                this.successful = false;
                this.loading = false;
              }else if(response.data === 'userNotExist'){
                this.successful = false;
                this.loading = false;
                this.message = "User does not exist.";
              }


            })
            .catch(error => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
              this.message =
                  (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString();
              //this.message = JSON.stringify(error) || error;
              this.successful = false;
              this.loading = false;
            })


      }else if(this.pageStatus === 'validateResetCode'){
        this.validateResetCode();
        if(this.hasValidationError){
          this.loading = false;
          return;
        }
        this.axios
            .post(this.$hostApi + 'resetpass/',{task:'validateResetCode', email:this.email, resetCode:this.resetCode})
            .then(response =>{
              //console.log(response);
              if(response.data === 'ok'){
                this.pageStatus = 'resetPassword';
                this.successful = false;
                this.loading = false;
              }else if(response.data === 'no'){
                this.resetCodeValid = false;
                this.successful = false;
                this.loading = false;
                this.message = 'Invalid verification code!'
                //console.log(this.message);
              }
            })
            .catch(error => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
              this.message =
                  (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString();
              //this.message = JSON.stringify(error) || error;
              this.successful = false;
              this.loading = false;
            })


      }else if(this.pageStatus === 'resetPassword'){
        this.validatePassword();
        this.validatePasswordConfirmation();


        if(this.hasValidationError){
          this.successful = false;
          this.loading = false;
          return;
        }


        this.axios
            .post(this.$hostApi + 'resetpass/',{task:'resetPassword', email:this.email, resetCode:this.resetCode, password:this.password})
            .then(response =>{
              //console.log(response);
              if(response.data === 'ok'){
                this.pageStatus = 'resetSuccess';
                this.successful = false;
                this.loading = false;
              }
            })
            .catch(error => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            })
      }


    },

    showRegForm(){

    },

    validateEmail() {
      //console.log('email is '+this.email);
      let errors = (/\S+@\S+\.\S+/.test(this.email)) ? '' : '<li>email is not valid</li>';
      errors += this.$root.validateLength(this.email,0,100);
      this.emailErrors = errors;
      if(this.emailErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateResetCode(){
      this.resetCodeErrors = this.$root.validateLength(this.resetCode,0,10);
      if(this.resetCodeErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validatePassword(){
      this.passwordErrors = this.$root.validateLength(this.password,6,20);
      if(this.passwordErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validatePasswordConfirmation(){
      this.passwordConfirmationErrors = this.$root.validateLength(this.passwordConfirmation,6,20);
      if(this.passwordConfirmationErrors.length > 0){
        this.hasValidationError = true;
      }

      if(this.passwordConfirmation !== this.password){
        this.passwordConfirmationErrors += "<li>passwords don't match</li>";
        this.hasValidationError = true;
      }
    },
  },
};
</script>

<style scoped>
#logoDiv{
  margin:0 auto;
  padding:10px 20px;
  text-align: center;
}


.error-feedback{
  font-size:10px;
  color:red;

}

.error-feedback-div{
  margin-bottom:5px;
}

.form-group{
  margin:20px;
}

.resetSuccess{
  margin-top:50px;
  padding:10px 20px;
}


</style>